<template>
  <div class="feed" v-scroll="handleScroll">
    <div class="wrap post" id="content">
      <!-- <div v-if="!load" class="load text-center mt-10">
        <v-progress-circular indeterminate color="secondary" size="200" width="16"></v-progress-circular>
      </div> -->
      <div v-if="!load" class="px-md-6 py-md-4 px-4 py-3">
        <v-skeleton-loader type="list-item-avatar-two-line, list-item-two-line, image, list-item"></v-skeleton-loader>
      </div>
      <v-card v-else-if="error == 403" tile flat class="mt-10 text-center primary--text px-md-6 py-md-4 px-4 py-3">
        <img class="empty-img" src="@/assets/img/empty.svg" alt="empty" />
        <div class="text-h5 font-weight-black mt-3">{{ $t('postItem.noPost.title') }}</div>
        <div class="mt-1 text-block">{{ $t('postItem.noPost.desc') }}</div>
      </v-card>
      <v-card v-else-if="error >= 500" tile flat class="mt-10 text-center primary--text px-md-6 py-md-4 px-4 py-3">
        <img class="empty-img" src="@/assets/img/empty.svg" alt="empty" />
        <div class="text-h5 font-weight-black mt-3">{{ $t('postItem.noFound.title') }}</div>
        <div class="mt-1 text-block">{{ $t('postItem.noFound.desc') }}</div>
      </v-card>
      <v-card v-else tile flat class="card primary--text px-md-6 py-md-4 px-4 py-3" v-for="(i, k) in list" :key="i.id">
        <div class="link" @click="$router.push(`/posts/${i.post.id}`)">
          <div class="d-flex" v-if="i[i.type]">
            <v-avatar size="60" color="accent">
              <v-img cover v-if="i[i.type].created_by.picture" :src="i[i.type].created_by.picture" alt="avatar"></v-img>
              <v-icon v-else>mdi-account mdi-36px</v-icon>
            </v-avatar>
            <div class="ms-4">
              <div class="f18 font-weight-medium">
                {{ i[i.type].created_by.first_name }} {{ i[i.type].created_by.last_name }}
                <span class="f15 gray--text text--lighten-5">
                  <v-card flat color="d-inline-block gray lighten-5 rounded-circle mx-2 mb-1" width="4" height="4"></v-card>
                  {{
                    new Date(i[i.type].created_at).toLocaleString(select.key == 'en' ? 'en-US' : 'ar-EG', {
                      day: 'numeric',
                      year: 'numeric',
                      month: 'short',
                    })
                  }}
                </span>
              </div>
              <div class="d-flex flex-wrap" v-if="i[i.type].created_by.cars.length">
                <div
                  v-for="(item, j) in i[i.type].created_by.cars"
                  :key="item.id"
                  class="cars-item"
                  :class="i[i.type].created_by.cars.length <= 4 ? '' : j > 2 ? 'd-none' : ''"
                  :style="`z-index: ${5 - j}`"
                >
                  <img v-if="item.vendor" class="d-block rounded-circle" height="24" width="24" :src="item.vendor.icon" alt="icon" />
                  <v-card flat v-else class="gray lighten-4 rounded-circle" width="24" height="24"></v-card>
                </div>
                <div class="topic-item primary--text text-body-2" v-if="i[i.type].created_by.cars.length - 4 > 0">
                  +{{ i[i.type].created_by.cars.length - 3 }}
                </div>
              </div>
              <!-- <div class="gray--text f15">
                {{ i[i.type].created_by.country ? i[i.type].created_by.country.name : 'Unknown' }}
                {{ i[i.type].created_by.city ? `, ${i[i.type].created_by.city.name}` : '' }}
                <span class="vert mx-2"></span>
                {{ new Date(i[i.type].created_at).toLocaleString('en-US', { day: 'numeric', year: 'numeric', month: 'short' }) }}
              </div> -->
            </div>
          </div>
          <div class="d-flex flex-wrap mt-2" v-if="i[i.type].topics.length">
            <div
              v-for="(item, j) in i[i.type].topics"
              :key="item.id"
              class="topic-item"
              :class="i[i.type].topics.length <= 4 ? '' : j > 2 ? 'd-none' : ''"
            >
              <img v-if="item.icon" class="d-block rounded-circle" height="18" width="18" :src="item.icon" alt="icon" />
              <v-card flat v-else class="gray lighten-4 rounded-circle" width="18" height="18"></v-card>
              <div class="ms-2">{{ item.title }}</div>
            </div>
            <div class="topic-item primary--text text-body-2 rounded-circle justify-center px-0" v-if="i[i.type].topics.length - 4 > 0">
              +{{ i[i.type].topics.length - 3 }}
            </div>
          </div>
          <div class="mt-4 mb-1 post-text" :key="key">
            {{ i[i.type].text | shorten(text[k]) }}{{ i[i.type].text.split(' ').length > 20 && text[k] == 20 ? '...' : '' }}
          </div>
        </div>
        <div
          v-if="i[i.type].text.split(' ').length > 20"
          class="blue--text d-inline-block text-body-2 font-weight-medium link mb-2"
          @click.prevent="(text[k] = text[k] == 20 ? i[i.type].text.split(' ').length : 20), ++key"
        >
          {{ text[k] == 20 ? $t('btn.showMore') : $t('btn.showLess') }}
          <v-icon class="blue--text f18">mdi-chevron-{{ text[k] == 20 ? 'down' : 'up' }}</v-icon>
        </div>
        <v-carousel
          v-if="i[i.type].attachments.length"
          :hide-delimiters="i[i.type].attachments.length <= 1"
          hide-delimiter-background
          :show-arrows="false"
          :height="healthAtt"
          style="border-radius: 10px"
        >
          <v-carousel-item v-for="item in i[i.type].attachments" :key="item.id" class="accent darken-1">
            <v-img v-if="item.type == 'picture'" cover :width="'100%'" :height="healthAtt" :src="item.url"></v-img>
            <!-- <video
              v-else
              ref="videoPlayer"
              controlsList="nodownload"
              :poster="item.preview_url ? item.preview_url : ''"
              controls="controls"
              :width="'100%'"
              :height="healthAtt"
            >
              <source :src="item.url" type="application/x-mpegURL" />
            </video> -->
            <VideoPlayer v-else :options="optionVideo(item)" />
          </v-carousel-item>
        </v-carousel>
        <div class="mt-4 d-flex link" @click="$router.push(`/posts/${i.post.id}`)">
          <div class="d-flex align-center me-4 gray--text">
            <img
              :class="i[i.type].is_liked ? 'like-color' : ''"
              :src="require(`@/assets/icon/${i[i.type].is_liked ? 'heart-fill' : 'heart'}.svg`)"
              alt="like"
            />
            <div v-if="i[i.type].likes_count" class="ms-2 text-body-2 font-weight-medium">{{ i[i.type].likes_count }}</div>
          </div>
          <div class="d-flex align-center gray--text">
            <img src="@/assets/icon/chat.svg" alt="chat" />
            <div class="ms-2 text-body-2 font-weight-medium">{{ i[i.type].comments_count }}</div>
          </div>
          <v-spacer></v-spacer>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <img v-bind="attrs" v-on="on" class="link" src="@/assets/icon/share.svg" alt="share" />
            </template>
            <span>Share</span>
          </v-tooltip> -->
        </div>
      </v-card>
      <div class="text-center py-5 loader" v-if="loadContent">
        <v-progress-circular :size="50" :width="5" indeterminate color="primary"></v-progress-circular>
      </div>
      <v-card
        :width="width <= 1280 ? '310' : '318'"
        outlined
        color="accent"
        class="pa-lg-6 pa-4 pb-lg-8 pb-6 app d-md-block d-none"
        :style="`top: ${scrollPage >= 72 ? '12' : '84'}px`"
      >
        <div class="primary--text text-h5 font-weight-black">{{ $t('apps.title') }}</div>
        <div class="primary--text mt-1">{{ $t('apps.desc') }}</div>
        <div class="mt-lg-6 mt-4 d-flex justify-space-between flex-wrap">
          <a href="https://apps.apple.com/us/app/snobar/id1613143471" target="_blank" class="d-block" style="height: 40px">
            <img
              height="40"
              width="120"
              :src="require(`@/assets/icon/apple${select.key == 'ar' ? '-ar' : ''}.png`)"
              alt="apple"
              :srcset="getImgSet(select.key == 'ar' ? 'apple-ar' : 'apple')"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=app.snobar.client" target="_blank" class="d-block" style="height: 40px">
            <img
              height="40"
              width="120"
              :src="require(`@/assets/icon/google${select.key == 'ar' ? '-ar' : ''}.png`)"
              alt="google"
              :srcset="getImgSet(select.key == 'ar' ? 'google-ar' : 'google')"
            />
          </a>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import VideoPlayer from '@/components/player.vue';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    VideoPlayer,
  },
  data() {
    return {
      load: false,
      loadContent: false,
      key: 1,
      scrollPage: 0,
      page: 1,
      healthAtt: 300,
      list: [],
      text: [],
      error: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await this.$store
        .dispatch('getFeedList', this.page)
        .catch((e) => {
          this.error = e.response.status;
          if (this.error == 404) {
            this.$router.push('/404');
          }
        })
        .finally(() => {
          this.load = true;
          this.loadContent = false;
        });
    },
    moreContent() {
      if (this.page - 1 == this.data.page && this.page != this.data.pages) {
        const fullH = document.getElementById('content').clientHeight;
        if (fullH - window.innerHeight <= pageYOffset) {
          this.loadContent = true;
          ++this.page;
          this.$nextTick(() => {
            this.getData();
          });
        }
      }
    },
    optionVideo(i) {
      const f = i.url.substr(-4);
      return {
        autoplay: false,
        controls: true,
        sources: [{ src: i.url, type: f == 'm3u8' ? 'application/x-mpegURL' : 'video/mp4' }],
      };
    },
    handleScroll() {
      this.scrollPage = window.pageYOffset;
    },
    getImgSet(img) {
      return `
        ${require(`@/assets/icon/${img}-2x.png`)} 2x,
        ${require(`@/assets/icon/${img}-3x.png`)} 3x
      `;
    },
  },
  filters: {
    shorten: (val, words) => val.split(' ').slice(0, words).join(' '),
  },
  watch: {
    data(val) {
      if (val.pages == 0) {
        this.list = [];
        for (let i = 0; i < this.data.results.length; i++) {
          this.list.push(this.data.results[i]);
          this.text.push(20);
        }
      } else {
        for (let i = 0; i < this.data.results.length; i++) {
          this.list.push(this.data.results[i]);
          this.text.push(20);
        }
      }
    },
    scrollPage() {
      if (this.page - 1 == this.data.page && this.page != this.data.pages) {
        const fullH = document.getElementById('content').clientHeight;
        if (fullH - window.innerHeight <= pageYOffset) {
          this.loadContent = true;
          ++this.page;
          this.$nextTick(() => {
            this.getData();
          });
        }
      }
    },
  },
  computed: {
    select() {
      return this.$store.getters.langValue;
    },
    data() {
      return this.$store.getters.feedList;
    },
    appIn() {
      return this.$route.query.hide_app_install == 'true' ? false : true;
    },
    width() {
      return this.$vuetify.breakpoint.width;
    },
  },
  destroyed() {
    this.$store.dispatch('setFeedList');
  },
};
</script>

<style lang="scss">
.feed {
  .post {
    min-height: calc(100vh - 72px);
    .card:first-child {
      padding-bottom: 20px;
    }
    .card:not(:first-child) {
      border-top: 1px solid #e0e0e0;
      padding: 20px 0;
    }
    .v-carousel__controls {
      left: 50% !important;
      transform: translate(-50%, 0);
      width: initial;
      .v-carousel__controls__item {
        margin: 0 2px;
      }
      .v-carousel__controls__item .v-icon {
        opacity: 1;
      }
      .btn--active::before {
        display: none !important;
      }
      .v-btn--icon.v-size--small,
      .v-btn--icon.v-size--small .v-icon,
      .v-btn--fab.v-size--small .v-icon {
        height: 10px;
        width: 10px;
        font-size: 10px !important;
      }
      .v-btn--active {
        color: var(--v-blue-base);
      }
    }
    .loader {
      border-top: 1px solid #e0e0e0;
    }
  }
  .post-text {
    font-size: 15px;
    white-space: pre-wrap;
  }
  .empty-img {
    object-fit: cover;
    max-width: 335px;
    width: 100%;
  }
  .load {
    height: 400px;
  }
  .vert {
    display: inline-block;
    width: 1px;
    height: 12px;
    background: #e0e0e0;
  }
  .app {
    position: fixed;
    top: 84px;
    left: calc(50% + 300px);
    border-radius: 10px !important;
    border-color: #e0e0e0 !important;
  }
  .text-block {
    max-width: 340px;
    width: 100%;
    margin: 0 auto;
  }
}
.v-application--is-rtl {
  .feed {
    .app {
      right: calc(50% + 300px);
      left: initial;
    }
  }
}
@media screen and (max-width: 1240px) {
  .feed {
    .app {
      display: none !important;
    }
  }
}
</style>
